<template>
	<b-overlay :show="loading" variant="light" spinner-type="grow">
		<b-form @submit.prevent="saveRecord" class="text-left m-2">

			<b-alert :show="!!error" variant="danger" v-html="error.message"></b-alert>

			<b-card class="mb-2">

				<b-form-group
						label="Imported on:"
						label-for="importDate"
						class="mb-1 p-2 shadow-sm"
				>
					<b-form-datepicker
							id="importDate"
							disabled
							v-model="importedDate"
					></b-form-datepicker>
				</b-form-group>

				<b-form-group class="mb-1 px-2 py-4 shadow-sm">

					<h4>Transactions:</h4>

					<b-icon :icon="statusIcon" :class="statusIconClass"></b-icon>
					<span class="pl-2" v-html="completionStatus"></span>

					<table class="table mt-4 table-hover">
						<thead>
							<tr>
								<th>Date</th>
								<th>Amount</th>
								<th>Student</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr
									v-for="trans of record.transactions"
									:key="trans._id"
							>
								<td>{{ trans.dateFormatted }}</td>
								<td>{{ trans.amountFormatted }}</td>
								<td>
									<span v-if="trans.studentId">{{ trans.studentName }}</span>
									<b-badge v-else variant="danger">Unmatched</b-badge>
								</td>
								<td>
									<b-button
											size="sm"
											:variant="trans.studentId ? 'light' : 'danger'"
											@click="matchStudent(trans)"
									>Match
									</b-button>
								</td>
							</tr>
						</tbody>
					</table>

				</b-form-group>

			</b-card>

			<div class="d-flex justify-content-end">
				<b-button v-if="!isNew" type="button" class="text-danger" variant="link"
				          @click="deleteRecord">
					Delete
				</b-button>
				<b-button type="button" :disabled="!isNew && !bvDataForm.dirty" class="ml-2 mr-0" variant="secondary"
				          @click="bvDataFormReset">
					{{ isNew ? 'Cancel' : 'Reset' }}
				</b-button>
				<b-button type="submit" :disabled="!bvDataForm.dirty" class="ml-2 mr-0" variant="primary">
					{{ isNew ? 'Save' : 'Save Changes' }}
				</b-button>
			</div>

		</b-form>
	</b-overlay>
</template>

<script>

import { cloneDeep, omit, orderBy } from 'lodash-es';
import bvDataFormMixin              from '@/mixins/bvDataForm.mixin';
import { DateTime }                 from 'luxon';
import { v4 as UUID }               from 'uuid';

export default {

    name      : 'DataFormPaymentImport',
    mixins    : [ bvDataFormMixin ],
    components: {},

    props: {

        record: {
            type: Object
        },

        students: {
            type    : Array,
            required: true
        }

    },

    computed: {

        isNew () {
            return this.record && !this.record.id;
        },

        totalTransactions () {
            return this.record.transactions.length;
        },

        matchedTransactions () {
            return this
                .record
                .transactions
                .filter ( x => x.studentId )
                .length;
        },

        completionStatus () {
            if ( this.matchedTransactions === this.totalTransactions ) {
                return `Import completed, all ${ this.totalTransactions } payments matched.`;
            } else {
                return `Import incomplete. <b>${ this.totalTransactions - this.matchedTransactions } transactions remain unmatched</b> from a total of ${ this.totalTransactions }.`;
            }
        },

        statusIcon () {
            return this.matchedTransactions === this.totalTransactions ? 'check-circle-fill' : 'exclamation-circle-fill';
        },

        statusIconClass () {
            return this.matchedTransactions === this.totalTransactions ? 'text-success' : 'text-danger';
        },

        importedDate () {
            return this.isNew
                ? DateTime
                    .utc ()
                    .toString ()
                : this.record.createdAt;
        }

    },

    data () {
        return {
            bvDataForm: this.bvDataFormGetDataDefaults (
                {
                    id          : 'paymentImportsForm',
                    resourceName: 'payment-import'
                }
            ),
            loading   : true,
            error     : false
        };
    },

    watch: {

        record: function () {
            this.decorateTransactions ();
        }

    },

    methods: {

        async saveRecord () {
            try {

                this.loading = true;
                this.error = false;

                const saveData = cloneDeep ( omit ( this.record, this.$utils.auditFields ) );

                // Save changes
                await this.bvDataFormSave ( saveData );
                if ( this.isNew ) {

                    this.$utils.logger ( 'info', '[AUTH] New payments imported.' );
                    this.$bvToast.toast (
                        'New payment import saved. All new matched payments were created.',
                        {
                            title        : 'Payments created',
                            autoHideDelay: 3000,
                            toaster      : 'b-toaster-top-center',
                            solid        : true
                        }
                    );

                } else {

                    this.$utils.logger (
                        'info',
                        '[AUTH] Changes saved to payment import, changed payments were updated.'
                    );
                    this.$bvToast.toast (
                        'Changes for payment were saved successfully.',
                        {
                            title        : 'Saved',
                            autoHideDelay: 3000,
                            toaster      : 'b-toaster-top-center',
                            solid        : true
                        }
                    );
                }

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        async deleteRecord () {
            try {

                this.loading = true;
                this.error = false;

                // Delete record
                const response = await this.bvDataFormDelete ();
                if ( response !== false ) {

                    this.$utils.logger ( 'info', '[AUTH] Payment import deleted.', response.data );
                    this.$bvToast.toast (
                        'Payment import, and all related payments were deleted successfully.',
                        {
                            title        : 'Deleted',
                            autoHideDelay: 3000,
                            toaster      : 'b-toaster-top-center',
                            solid        : true
                        }
                    );

                }

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        async matchStudent ( trans ) {

            await this.$alertVue (
                {
                    title            : 'Match a transaction to a student',
                    confirmButtonText: 'Match',
                    showCancelButton : true,
                    width            : '50rem'
                },
                'PaymentMatchForm',
                {
                    students   : this.students,
                    studentId  : trans.studentId,
                    description: trans.description
                },
                async ( component ) => {

                    if ( component.matched ) {

                        const transaction = this.record.transactions.find ( x => x._id === trans._id );

                        this.$set ( transaction, 'studentId', component.student.value );
                        this.$set ( transaction, 'studentName', component.student.text );

                    }

                }
            );

        },

        decorateTransactions () {

            // Decorate each transaction with a unique id
            const decorated = this.record.transactions.map ( x => {

                const student = this.students.find ( y => y.value === x.studentId );

                x._id = UUID ();
                x.dateFormatted = DateTime
                    .fromISO ( x.date )
                    .toLocaleString ( DateTime.DATE_MED )
                ;
                x.amountFormatted = '£' + x.amount.toFixed ( 2 );
                x.studentName = student ? student.text : null;

                return x;

            } );

            // Put unmatched at the top and order the rest by date then student
            const unmatched = decorated.filter ( x => !x.studentId );
            const matched = orderBy (
                decorated.filter ( x => x.studentId ),
                [ 'date', 'studentName' ],
                [ 'asc', 'asc' ]
            );
            this.record.transactions = unmatched.concat ( matched );

        }

    },

    mounted () {

        this.decorateTransactions();

        // Ready
        this.loading = false;

    }

};

</script>

<style scoped lang="scss">

.inline-select {
	margin-top: -.3rem;
	width: auto;
	height: auto;
	padding: .3rem;
}

</style>
<template>
	<div class="bg-white p-4 rounded border shadow-sm h-100">
		<b-overlay :show="loading" variant="light" spinner-type="grow">

			<section class="rounded bg-light-app px-4 pt-4 pb-2 mb-4 position-relative">
				<h1 class="display-4">
					Payment Imports
					<small class="text-muted">Manage and match your imported payments</small>
				</h1>
				<b-button variant="primary"
				          @click="add"
				          class="position-absolute"
				          style="right: 1rem; top: 1rem;">
					<b-icon icon="plus" font-scale="2"></b-icon>
				</b-button>
			</section>

			<div class="page-inset shadow">

				<error-alert :error="error" class="m-4"/>

				<splitpanes class="default-theme" :push-other-panes="false">

					<pane size="50" min-size="30" max-size="90">
						<b-navbar type="dark" variant="dark" class="p-1">

							<b-nav-text class="ml-2">{{ bvDataTablePaginationInfo }}</b-nav-text>

							<b-navbar-nav class="ml-auto mr-2">

								<b-nav-item-dropdown right no-caret>
									<template #button-content>
										<b-icon icon="download" aria-hidden="true"></b-icon>
									</template>
									<b-dropdown-item @click="bvDataTableDownloadAll('json')">
										Download all payment imports (JSON)
									</b-dropdown-item>
									<b-dropdown-item @click="bvDataTableDownloadAll('csv')">
										Download all payment imports (CSV)
									</b-dropdown-item>
								</b-nav-item-dropdown>

							</b-navbar-nav>

						</b-navbar>

						<div class="d-lg-flex flex-lg-row align-content-center justify-content-between my-3">

							<div>
								<b-input-group class="pr-4 pr-lg-0 ml-lg-2">

									<template #prepend>
										<b-button :disabled="!bvDataTable.textFilter" variant="faded" size="sm"
										          class="shadow-none"
										          @click="bvDataTable.textFilter = null">
											<b-icon v-if="!bvDataTable.textFilter" icon="search"
											        aria-hidden="true"></b-icon>
											<b-icon v-if="bvDataTable.textFilter" icon="x-circle-fill"
											        aria-hidden="true"></b-icon>
										</b-button>
									</template>

									<b-form-input
											v-model="bvDataTable.textFilter"
											size="md"
											placeholder="Search"
									></b-form-input>

								</b-input-group>
							</div>

							<div>
								<b-pagination
										v-model="bvDataTable.currentPage"
										:total-rows="bvDataTable.totalRecords"
										:per-page="bvDataTable.rowsPerPage"
										aria-controls="paymentImportsTable"
										align="fill"
										class="m-4 m-lg-0"
										style="margin-top: 7px !important;"
								></b-pagination>
							</div>

							<div class="mb-4 mb-lg-0 pr-lg-4 text-center text-lg-right">

								<b-dropdown>
									<template #button-content>
										{{ bvDataTable.rowsPerPage }} rows per page
									</template>
									<b-dropdown-item
											v-for="rpp of bvDataTable.rowsPerPageOptions"
											:key="rpp"
											:active="bvDataTable.rowsPerPage === rpp"
											@click="bvDataTable.rowsPerPage = rpp"
									>{{ rpp }}
									</b-dropdown-item>
								</b-dropdown>

							</div>

						</div>

						<b-table
								v-if="!loading"
								striped
								hover
								responsive="true"
								:id="bvDataTable.id"
								:items="bvDataTableFetch"
								:busy.sync="bvDataTable.fetchingData"
								:fields="bvDataTable.headers"
								:primary-key="'id'"
								:per-page="bvDataTable.rowsPerPage"
								:current-page="bvDataTable.currentPage"
								:filter="bvDataTable.textFilter"
								selectable
								selected-variant="primary"
								select-mode="single"
								@row-selected="bvDataTableSelectRow"
						></b-table>
					</pane>

					<pane size="50">

						<b-alert v-if="!bvDataTable.currentRecord" variant="info" show class="m-2 rounded shadow-sm">
							No import selected.
						</b-alert>

						<b-navbar v-if="bvDataTable.currentRecord" type="dark" variant="dark" class="p-1"
						          style="height: 56px">

							<b-nav-text class="ml-2">{{ currentPaymentImportName }}</b-nav-text>

						</b-navbar>

						<data-form-payment-import
								v-if="bvDataTable.currentRecord"
								:record="bvDataTable.currentRecord"
								:students="students"
								@reset="bvDataTableRestoreCurrentRecord"
								@changed="bvDataTableUpdateCurrentRecord"
								@deleted="bvDataTableDeleteCurrentRecord"
						/>

					</pane>

				</splitpanes>

			</div>

		</b-overlay>
	</div>
</template>

<script>

import bvDataTableMixin      from '@/mixins/bvDataTable.mixin';
import DataFormPaymentImport from '@/components/data-forms/PaymentImport';
import { DateTime }          from 'luxon';
import { orderBy }           from 'lodash-es';
import PapaCSV               from 'papaparse';

export default {

    name      : 'PaymentImports',
    mixins    : [ bvDataTableMixin ],
    components: { DataFormPaymentImport },

    props: {},

    data () {
        return {

            bvDataTable: this.bvDataTableGetDataDefaults (
                {
                    id            : 'paymentImportsTable',
                    collectionName: 'payment-imports',
                    resourceName  : 'payment-import',
                    headers       : [
                        { key: 'createdAt', label: 'Import Date', sortable: true },
                        { key: 'fileName', label: 'Filename', sortable: true },
                        { key: 'completed', label: 'Completed?', sortable: false, thClass: 'text-center', tdClass: 'text-center' },
                        { key: 'total', label: 'Payments', sortable: false, thClass: 'text-center', tdClass: 'text-center' }
                    ]
                }
            ),

            loading: true,
            error  : false,

            students         : [],
            paymentReferences: []

        };
    },

    computed: {

        currentPaymentImportName () {

            if ( !this.bvDataTable.currentRecord ) {
                return null;
            }

            if ( !this.bvDataTable.currentRecord.id ) {
                return `New import - ${ this.bvDataTable.currentRecord.fileName }`;
            }

            return this.bvDataTable.currentRecord.fileName;

        }

    },

    methods: {

        // Format a single record for display
        bvDataTableFormatRow ( row ) {

            row.createdAt = DateTime
                .fromISO ( row.createdAt )
                .toLocaleString ( DateTime.DATE_MED )
            ;

            row.completed = row.transactions.filter ( x => !x.paymentId ).length === 0 ? '✅' : '❌';
            row.total = row.transactions.length;

            return row;

        },

        async add () {

            await this.$alertVue (
                {
                    title            : 'Import a new payment file',
                    confirmButtonText: 'Import',
                    showCancelButton : true,
                    width            : '40rem'
                },
                'PaymentUploadForm',
                {
                    students: this.students,
                    paymentReferences: this.paymentReferences
                },
                async ( component ) => {

                    if ( ( component.transactions || [] ).length === 0 ) {

                        this.$bvToast.toast (
                            'No payments were imported.',
                            {
                                title        : 'Cancelled.',
                                autoHideDelay: 3000,
                                toaster      : 'b-toaster-top-center',
                                solid        : true
                            }
                        );

                    } else {

                        // Create a new blank record
                        this.$set ( this.bvDataTable, 'currentRecord', {
                            fileName    : component.importFile.name,
                            transactions: component.transactions
                        } );

                    }

                }
            );

        },

        async bvDataTableDownloadAll ( format ) {

            this.bvDataTable.fetchingData = true;

            try {

                const { data } = await this.$http.get (
                    `${ this.config.apiRoot }/${ this.bvDataTable.collectionName }?offset=0&limit=0`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                switch ( format ) {

                    case 'csv':

                        // Tidy the data up
                        const parsed = data.records.map ( x => {

                            const unmatched = x.transactions.filter ( y => !y.paymentId ).length;
                            return {
                                importDate  : x.createdAt.split ( 'T' )[ 0 ],
                                fileName    : x.fileName,
                                transactions: x.transactions.length,
                                unmatched   : unmatched,
                                'completed?': unmatched > 0 ? 'No' : 'Yes'
                            };

                        } );

                        const buffer = PapaCSV.unparse (
                            parsed,
                            {
                                quotes    : true,
                                quoteChar : '"',
                                escapeChar: '"',
                                delimiter : ','
                            }
                        );

                        this.$utils.downloadDataFile (
                            buffer,
                            'text/csv',
                            `${ this.bvDataTable.collectionName }.${ ( new Date () ).toISOString () }.csv`
                        );

                        break;

                    default:
                        this.$utils.downloadJSON (
                            data.records,
                            `${ this.bvDataTable.collectionName }.${ ( new Date () ).toISOString () }.json`
                        );

                }

                this.bvDataTable.fetchingData = false;

            } catch ( e ) {

                // Bubble the exception
                this.error = this.$utils.normaliseError ( e );
                this.$utils.logger ( 'error', '[STUDENTS] Error fetching data:', e );
                this.bvDataTable.fetchingData = false;

            }

        }

    },

    async mounted () {

        // Get students
        let response = await this.$http.get (
            `${ this.config.apiRoot }/students`,
            {
                responseType   : 'json',
                withCredentials: false // CORS dev server
            }
        );
        this.students = response.data.records.map ( x => {
            return {
                value     : x.id,
                identifier: x.identifier,
                text      : `${ ( x.firstName + ' ' + x.lastName ).trim () } (CEMAA${ x.identifier })`
            };
        } );
        this.students = orderBy ( this.students, [ 'text' ], [ 'asc' ] );

        // Get a list of additional class payment references
        response = await this.$http.get (
            `${ this.config.apiRoot }/classes`,
            {
                responseType   : 'json',
                withCredentials: false // CORS dev server
            }
        );
        this.paymentReferences = response.data.records
            .filter ( x => !!x.paymentReference )
            .map ( x => x.paymentReference )
        ;

        this.loading = false;

    }

};

</script>

<style scoped lang="scss">

</style>
